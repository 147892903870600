<template>
  <section class="who-we-are">
    <!-- HEADER -->
    <header class="hero-small">
      <div>
        <h1>
          Un constructeur de maisons <br/> individuelles innovant
        </h1>
      </div>
    </header>
    <!-- Main content -->
    <main>
      <div class="grid-container">
        <card-type-img title="Un concept unique fondé sur le circuit court" class="short-route">
          <template v-slot:content>
            <p>Véritable pionnier de la transition digitale dans le secteur de la construction de la maison individuelle, Maisons Alysia a créé un modèle économique unique en France. Dépourvu de commerciaux, il propose d'initier votre projet en ligne en devenant l'acteur principal de sa conception. C'est vous qui décidez d'aller plus loin et de bénéficier du relai et de l'expertise de votre constructeur. Quand circuit court rime avec liberté…</p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/circuit-court.png" alt="" />
          </template>
        </card-type-img>
        <div class="short-route-after">
          <p>Le site Maisons Alysia est doté d'un configurateur sophistiqué et extrêmement simple d'utilisation. Vous choisissez votre terrain et votre modèle de maison avec son niveau de finition. Vous avez la possibilité de personnaliser  votre projet en sélectionnant options et équipements parmi une large palette. Le prix de votre projet s'affiche en temps réel et vous obtenez d'un clic votre future mensualité personnalisée.
            <br/>C’est le futur acquéreur qui construit en ligne son projet en l’absence de tout commercial. Confort ET économie…
          </p>
        </div>
        <card-type-img title="Un modèle économique…très économique !" class="lowest-price">
          <template v-slot:content>
            <p>Adossé au principe digital, Maisons Alysia développe un fonctionnement permettant d’offrir les meilleures maisons et les meilleurs services aux meilleurs prix. Les moyens mis en œuvre sont multiples : </p>
            <ul>
              <li>Réduire au maximum les efforts commerciaux et publicitaires,</li>
              <li>Mutualiser les coûts et la gestion,</li>
              <li>Rationaliser le process de production,</li>
              <li>Optimiser la mise en œuvre et le suivi des chantiers.</li>
            </ul>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/lowest-price-on-market.png" alt="Les prix les plus bas du marché" />
          </template>
        </card-type-img>
        <card-type-img title="Un sésame à la propriété">
          <template v-slot:content>
            <p>Maisons ALYSIA permet aux primo-accédants un accès élargi à la maison individuelle en proposant :</p>
            <ul>
              <li>les tarifs les plus bas constatables sur le marché</li>
              <li>des accords spécifiques avec des partenaires financiers et des courtiers locaux, permettant des montages de dossiers financiers ultra-performants</li>
            </ul>
            <p>Véritable facilitateur, Maisons ALYSIA déverrouille le marché en le rendant accessible à des ménages n’en ayant jusqu’à présent pas la possibilité.</p>
            <p>« 9 personnes sur 10 ne savaient pas qu’elles pouvaient devenir propriétaires de leur maison pour le prix d’un loyer avant de découvrir les offres Maisons ALYSIA.»</p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/family-go-into-screen.png" alt="Les prix les plus bas du marché" />
          </template>
        </card-type-img>
      </div>
    </main>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  components: {
    CardTypeImg,
  },
  data() {
    return {};
  },
};
</script>

<style lang="sass">
.who-we-are
  header
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/happy-family-planning-their-new-apartment.png)
  main
    background-color: $medium-bg
    padding: 2rem 0
    .card-type-img
      margin-top: 3rem
      p
        margin: 25px 0
      &:not(.founder-card)
        figure
          display: flex
          align-items: center
          justify-content: center
          padding: 0 2rem
          img
            object-fit: contain !important
      &.short-route
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    .short-route-after
      text-align: center
      background-color: $primary
      padding: 2.5rem
      border-radius: 0 0 $global-border-radius $global-border-radius
      p
        color: $white
        width: 80%
        margin: auto
  @media (max-width: 768px)
    main
      .founder-card
        img
          object-position: center -15px
      .short-route
        flex-direction: column
      .short-route-after
        padding: 2rem 1rem
</style>
